import React, { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
function ExpenseSlip() {
    const startYear = 2022;
    const endpoint = new URL(window.location.href).pathname;
  const receiptContent = React.createRef();
  const currentYear = new Date().getFullYear();
  const currentMonth = monthNames[new Date().getMonth()];
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(currentYear);

  const [month, setMonth] = useState(currentMonth);
  const { state } = useLocation();
  const { profile } = useSelector((state) => state.profile);
  const [totalExpense , setTotalExpense]=useState("");

  const [values, setValues] = useState(profile);

  const handlePrintReceipt = useReactToPrint({
    content: () => receiptContent.current,
    documentTitle: "Salary Slip",
    style: `
      @media print {
        body {
          background-color: white !important;
          color: black !important;
        }
        .print-content {
          background-color: white !important;
          color: black !important;
          border: none !important;
          width: 100% !important;
          padding: 30px;
        }
        .table th, .table td {
          border: 1px solid #000 !important;
        }
      }
    `,
  });

  useEffect(() => {
    const yearsArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
    console.log("State data:", state);
  }, []);

  useEffect(()=>{
    const getTotalExpense=()=>{
      let api;

      if(endpoint=="/income-slip"){
        api="income/getmonthlyincome"
      }else{
        api="expense/getmonthlyexpense"
      }
        axios.post(`${process.env.REACT_APP_BASE_URL}${api}`,{year,month},{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                'Content-Type': 'application/json',
                "version":"1.0.0"
            }
        }).then((response)=>{
            if(response.data.success){
                setTotalExpense(response.data.data.totalAmount)
            }else{
                console.log(response.data.message);
            }
        }).catch((response)=>{
            console.log(response);
        });

    }
    getTotalExpense();
    // handlePrintReceipt();

  },[year,month])

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
            <div className="row">
                <div className="col-3 year-select-view-salary">
                  <select
                    className="form-control"
                    onChange={(e) => setYear(e.target.value)}
                    name="year"
                    value={year}
                  >
                    <option value="">--Select Year--</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-3 year-select-view-salary">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => setMonth(e.target.value)}
                      name="month"
                      value={month}>
                     {monthNames.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                    </select>
                  </div>
                </div>
              
              </div>
              <div className="p-4 print-content" ref={receiptContent}>
                <div className="text-center">
                  <h2>{values?.companyName || "ROYAL FINANCIAL SERVICES"}</h2>
                  <p>{values?.address || "KUNJ PLAZA, 2nd FLOOR, JNASHI ROAD, DABRA, DIST-GWL"}</p>
                  <p>{`MOB: ${values?.mobile_no || "_________"}`}</p>
                  <h4>MONTHLY {endpoint=="/income-slip"?"INCOME":"EXPENSE"} SLIP</h4>
                </div>
                <div>
                    <p>Date Of Issue:{moment().format('DD-MMM-YYYY')||"_________________"}</p>
                    <p>{endpoint=="/income-slip"?"Income":"Expense"} Period:{moment().startOf('month').format('DD-MMM-YYYY')+ "  " +"To" + "  "+ moment().endOf('month').format('DD-MMM-YYYY')}</p>

                </div>
                <br/>
                <br/>
             
                {/* <h4 style={{ textAlign: "center", marginTop: "20px" }}>Expense Details</h4> */}
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid grey", padding:"15px" }}>Total January {endpoint=="/income-slip"?"Income":"Expense"} </th>
              <td style={{ border: "1px solid grey",padding:"15px" }}>{totalExpense || "________"} Rs/-</td>

              
            </tr>
          </thead>
        </table>
                <div className="row mt-3">
                  <div className="col text-start mt-5">
                    <p>Branch Manager: __________</p>
                  </div>
              <div className="col text-center mt-5">
              {"               "}<p>Director: _______________________</p>
                  </div>
                </div>
              </div>
              <button onClick={() => handlePrintReceipt()}>Print</button>
            </div>
          </div>
        </div>
      </div>


      <style>
        {`
          @media print {
            .print-content {
              background-color: white;
              padding: 40px;
              height: 100vh;
            }
          }
        `}
      </style>
    </Layout>
  );
}

export default ExpenseSlip;