import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-browser-router";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function FieldEmployeeSalary() {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentDate = new Date();
const currentMonthName = monthNames[currentDate.getMonth()];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [month, setMonth] = useState(currentMonthName);

  const currentYear = new Date().getFullYear();
  const startYear = 2018;

  const { state } = useLocation();
  console.log("state", state);
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [employeeId, setEmployeeId] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleOnSearch = (string, results) => {
  };
  const handleOnSelect = (item) => {
    setSelectedEmployee(item);
  };
  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>
        <p className="m-0">name: {item.name}</p>
        <p className="m-0">Mobile No.: {item.mobile_no}</p>
      </span>
    );
  };
  const getData = () => {
    const token = localStorage.getItem("token");

    axios
      .get(
        `${BASE_URL}employee/searchFieldEmployeesWithAssocLoan`,
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setEmployees(response.data.data);
          console.log("employees", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const yearsArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  
  }, []);
  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="row mb-4">
                <div className="col-6">
                  <ReactSearchAutocomplete
                    items={employees}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    placeholder="Search By Name"
                    autoFocus
                    formatResult={formatResult}
                  />
                </div>
                {
                  selectedEmployee&&selectedEmployee.unpaidLoans&&
                  <div className="col text-right">
                  <button className="btn btn-primary "  onClick={()=>navigate("/commission-salary",{state:selectedEmployee})}  >Generate Slip</button>
                    </div>
                }
               
              </div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black" }}>Name</th>
              <th style={{ border: "1px solid black" }}>Mobile Number</th>
              <th style={{ border: "1px solid black" }}>TYPE</th>
              <th style={{ border: "1px solid black" }}>Account Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black" }}>{selectedEmployee?.name || "________"}</td>
              <td style={{ border: "1px solid black" }}>{selectedEmployee?.mobile_no || "________"} </td>
              <td style={{ border: "1px solid black" }}>{selectedEmployee?.employee_type || "________"}</td>
              <td style={{ border: "1px solid black" }}>
                Account Holder Name: {selectedEmployee?.account_holder_name || "________"} <br/>
                Bank Name: {selectedEmployee?.bank_name || "________"} <br/>
                Account Number: {selectedEmployee?.account_number || "________"} <br/>
                IFSC Code: {selectedEmployee?.ifsc_code || "________"} <br/>



                </td>
            </tr>
          </tbody>
        </table>

              <h5 className="form-header">Associated Loan to which Commission Has To Be Given</h5>
              <div className="table-responsive">
                {loading ? (
                  <div className="d-flex justify-content-center align-item-center">
                    <span className="spinner-border spinner-border-sm"></span>
                  </div>
                ) : (
                  <table className="table table-lightborder">
                    <thead>
                      <tr>
                        <th>Loan Applicant Name</th>
                        <th>Mobile Number</th>
                        <th>Loan sanction</th>
                        <th>createdAt</th>
                        <th>mobifiedAt</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {selectedEmployee?.unpaidLoans?.length>=0&&selectedEmployee?.unpaidLoans?.map((item, index) => (
                          <tr key={index}>
                          <td>{item?.name}</td>
                          <td>{item?.mobile_no}</td>
                          <td>{item?.sanction}</td>
                           <td>
                            {moment(item?.createdAt).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                           <td>
                            {moment(item?.modified.on).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                          <td>{item?.status}</td>
                        </tr>
                          ))
                        }
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="row mb-4">
              </div>
              <h5 className="form-header">Past Loans for Already Generated Salary</h5>
              <div className="table-responsive">
                {loading ? (
                  <div className="d-flex justify-content-center align-item-center">
                    <span className="spinner-border spinner-border-sm"></span>
                  </div>
                ) : (
                  <table className="table table-lightborder">
                    <thead>
                      <tr>
                        <th>Loan Applicant Name</th>
                        <th>Mobile Number</th>
                        <th>Loan sanction</th>
                        <th>createdAt</th>
                        <th>mobifiedAt</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {selectedEmployee?.paidLoans?.length>=0&&selectedEmployee?.paidLoans?.map((item, index) => (
                          <tr key={index}>
                          <td>{item?.name}</td>
                          <td>{item?.mobile_no}</td>
                          <td>{item?.sanction}</td>
                           <td>
                            {moment(item?.createdAt).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                           <td>
                            {moment(item?.modified.on).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                          <td>{item?.status}</td>
                        </tr>
                          ))
                        }
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default FieldEmployeeSalary;
