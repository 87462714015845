import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import moment from "moment";
import { Link } from "react-browser-router";

const initialState = {
  year: "",
  month: "",
  name: "",
  date: "",
  amount: "",
  companyName: "",
  status: "Active",
};
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CreateExpense = () => {
  const currentYear = new Date().getFullYear();
  const endpoint = new URL(window.location.href).pathname;
  const currentMonth = monthNames[new Date().getMonth()];
  const [values, setValues] = useState(initialState);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(currentYear);

  const [month, setMonth] = useState(currentMonth);

  const [page, setPage] = useState(1);
  const startYear = 2022;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allExpense, setAllExpense] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const saveUpdatedHead = async () => {
    try {
      let api;

      if (endpoint == "/create-expense") {
        api = "expense/upsertexpense";
      } else {
        api = "income/upsertincome";
      }
      const token = localStorage.getItem("token");

      const response = await axios.post(`${BASE_URL}${api}`, values, {
        headers: {
          "x-access-token": token,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      });
      if (response.data.success) {
        setValues(initialState);
        toast.success(response.data.message);
        getData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateHead = (item) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setValues({
      id: item._id,
      name: item.name,
      month: item.month,
      year: item.year,
      amount: item.amount,
      date: item.date,
      status: item.status,
    });
  };

  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const obj = {
        page: page,
        limit: 8,
      };
      let api;
      if (month) obj.month = month;
      if (year) obj.year = year;

      if (endpoint == "/create-expense") {
        api = "expense/getexpense";
      } else {
        api = "income/getincome";
      }

      const response = await axios.post(`${BASE_URL}${api}`, obj, {
        headers: {
          "x-access-token": token,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      });
      if (response.data.success) {
        setAllExpense(response.data.data);
      } else {
        setAllExpense([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteExpense = async (id) => {
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    try {
      let api;
      if (endpoint == "/create-expense") {
        api = "expense/deleteexpense";
      } else {
        api = "income/deleteincome";
      }
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}expense/${api}/${id}`, {
        headers: {
          "x-access-token": token,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      });
      if (response.data.success) {
        toast.error(response.data.message);
        setAllExpense([]);
        getData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const yearsArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
    setValues((prevValues) => ({
      ...prevValues,
      year: currentYear,
      month: currentMonth,
    }));
    console.log("Endpoint:", endpoint);
  }, []);

  useEffect(() => {
    getData();
  }, [year, month,endpoint]);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <h6 className="element-header">Expenses </h6>
                <div className="element-box">
                  <div>
                    <div className="row">
                      <div className="col">
                        <h5 className="form-header">Add  {endpoint == "/create-expense" ? <>Expenses</> : <>Income</>}</h5>
                      </div>
                      <div className="col text-right">
                        {endpoint === "/create-expense" ? (
                          <Link className="btn btn-primary" to="/expense-slip">
                            Generate Slip
                          </Link>
                        ) : (
                          <Link className="btn btn-primary " to="/income-slip">
                            Generate Slip
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Select Year:</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="year"
                            value={values.year || currentYear.toString()}
                          >
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Select Month:</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="month"
                            value={values.month || currentMonth.toString()}
                          >
                            {monthNames.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>
                            Name Of The
                            {endpoint == "/create-expense" ? (
                              <> Product</>
                            ) : (
                              <> Company</>
                            )}{" "}
                            :{" "}
                          </label>
                          {endpoint == "/create-expense" ? (
                            <>
                              <input
                                className="form-control"
                                placeholder="Enter Name"
                                name="name"
                                onChange={(e) => handleInputChange(e)}
                                value={values.name}
                                type="text"
                              />
                            </>
                          ) : (
                            <>
                              <input
                                className="form-control"
                                placeholder="Enter Company Name"
                                name="companyName"
                                onChange={(e) => handleInputChange(e)}
                                value={values.companyName}
                                type="text"
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor> Amount:</label>
                          <input
                            className="form-control"
                            placeholder="Enter Name"
                            name="amount"
                            onChange={(e) => handleInputChange(e)}
                            value={values.amount}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">
                            {endpoint == "/create-expense" ? (
                              <> Expense</>
                            ) : (
                              <> Income</>
                            )}{" "}
                            Date:
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter Name"
                            name="date"
                            onChange={(e) => handleInputChange(e)}
                            value={moment(values.date).format("YYYY-MM-DD")}
                            type="date"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Status</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="status"
                            value={values.status}
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => saveUpdatedHead()}
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////// category listing  start */}

      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <h5 className="form-header">
                {endpoint == "/create-expense" ? <>Expenses</> : <>Income</>}{" "}
                List
              </h5>
              <div className="row">
                <div className="col-3 year-select-view-salary">
                  <select
                    className="form-control"
                    onChange={(e) => setYear(e.target.value)}
                    name="year"
                    value={year}
                  >
                    <option value="">--Select Year--</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-3 year-select-view-salary">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => setMonth(e.target.value)}
                      name="month"
                      value={month}
                    >
                      {monthNames.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      <th>Year</th>
                      <th>Month</th>
                      <th> {endpoint=="/create-expense"?"Name Of The Product":"Company Name"}</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allExpense?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.year}</td>
                        <td>{item.month}</td>
                        <td>{
                        endpoint=="/create-expense"?item.name:item.companyName}</td>
                        <td>{moment(item.date).format("YYYY-MM-DD")}</td>
                        <td>{item.amount}</td>
                        <td>{item.status}</td>

                        <td>
                          <button
                            className="border-0 bg-white"
                            onClick={() => updateHead(item)}
                          >
                            <i className="os-icon os-icon-pencil-2" />
                          </button>

                          <button
                            className="text-danger border-0 bg-white ps-2"
                            onClick={() => deleteExpense(item._id)}
                          >
                            <i className="os-icon os-icon-database-remove" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateExpense;
