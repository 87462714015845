import React, { useEffect, useState } from "react";
import { getEmployee } from "../../services/employee/employee";
import {
  ContactPeople,
  SaveContactPerson,
  SaveLoanCompanyContactPerson,
} from "../../services/Loan/contactPerson";
import { SaveLoanCompanyHistory } from "../../services/Loan/companyHistory";
import Modal from "react-bootstrap/Modal";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {getLoanHistory} from "../../services/Loan/loanhistory";

function LoanRequest({
  handleInputChange,
  values,
  SaveLoan,
  loanId,
  loading,
  setActiveTab,
  setSelectedLoanCompanies,
  selectedCompanies,
  LoanByLoanId,
  contactPerson,
  allCompanies,
  setAllCompanies,
}) {
  const navigate = useNavigate();
  const [sourcingType, setSourcingType] = useState("Office");
  const [employees, setEmployees] = useState("");
  const [contactPeople, setContactPeople] = useState([]);
  const [companyItem, setCompanyItem] = useState({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loanStatus, setLoanStatus] = useState("Pending Leed");

  // loan status
  const [remark, setRemark] = useState("");
  const [statusName, setStatusName] = useState("");
  const [loanCompanyStatus, setLoanCompanyStatus] = useState("Pending Leed");
  const [currentDate, setCurrentDate] = useState("");

  // contact info
  const [name, setName] = useState("");
  const [mobile_no, setMobileNo] = useState();

  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>
        <p className="m-0">name: {item.name}</p>
        <p className="m-0">Mobile No.: {item.mobile_no}</p>
      </span>
    );
  };

  const getEmployees = async () => {
    const data = await getEmployee();
    if (data.data.success) {
      setEmployees(data.data.data);
      console.log(data.data.data, 44444444);
    } else {
      setEmployees([]);
    }
  };

  const getContactPeople = async (id) => {
    const data = await ContactPeople(id);
    if (data.data.success) {
      setContactPeople(data.data.data);
    } else {
      setContactPeople([]);
    }
  };

  const saveContactPerson = async () => {
    const data = await SaveContactPerson({
      name: name,
      mobile_no: mobile_no,
      companyId: companyItem._id,
    });
    if (data.data.success) {
      saveLoanCompanyContactPerson(data.data.data._id);
      setShow1(false);
    }
  };

  const saveLoanCompanyContactPerson = async (ID) => {
    const data = await SaveLoanCompanyContactPerson({
      loanId: loanId,
      contactPersonId: ID,
      companyId: companyItem._id,
    });
    if (data.data.success) {
      LoanByLoanId(loanId);
    }
  };

  const saveLoanCompanyHistory = async (ID) => {
    const obj = {
      loanId: loanId,
      remark: remark,
      statusName: statusName,
      status: loanCompanyStatus,
      currentDate: currentDate,
    };
    obj.companyId = companyItem._id;

    const data = await SaveLoanCompanyHistory(obj);
    if (data.data.success) {
      setLoanStatus(loanCompanyStatus);
      if (loanCompanyStatus == "Final Leed") {
        SaveLoan("fourthStep", data.data.data._id, loanCompanyStatus);
      } else {
        SaveLoan("fourthStep", "", loanCompanyStatus);
      }
      setShow(false);
      toast.success(data.data.message);
    }
  };
  const handleOnSelect = (item) => {
    console.log(item);
    setName(item.name);
    setMobileNo(item.mobile_no);
  };

  const handleOnSearch = (string, results) => {
    console.log(string, results);
    setName(string);
    setMobileNo("");
  };

  const handleMultiselectChange = async (selectedList) => {
    setSelectedLoanCompanies(selectedList);
  };
  const ViewHistoryFn = (item) => {
    let obj = {
      companyId: item._id,
      loanId: loanId,
    };
    navigate("/history", { state: { obj } });
  };


  const getData = async(id) => {
    const obj = {
      companyId: id,
      loanId:loanId,
    };
    let data=await getLoanHistory(obj);
    if(data.data.success){
      console.log("data",data.data.data);
      setLoanCompanyStatus(data.data.data[0].status);
      setStatusName(data.data.data[0].statusName);
      setRemark(data.data.data[0].remark);
      setCurrentDate(data.data.data[0].currentDate);

    }else{
      setLoanCompanyStatus();
      setStatusName();
      setRemark();
      setCurrentDate();
    }

  };
  const handleShow = (item) => {
    setCompanyItem(item);
    getData(item._id);
    console.log("item", item);
    setShow(true);
  };
  const handleShow1 = (item) => {
    setCompanyItem(item);
    console.log("item", item);
    setShow1(true);
    getContactPeople(item._id);
  };
  useEffect(() => {
    getEmployees();
  }, []);
  return (
    <>
      <fieldset>
        <legend>
          <span>Loan Details</span>
        </legend>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label> Sourcing Type</label>
              <select
                className="form-control"
                onChange={(e) => setSourcingType(e.target.value)}
                name="sourcingType"
                value={sourcingType}
              >
                <option value="Office">Office</option>
                <option value="Field">Field</option>
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Sourcing</label>
              <select
                className="form-control "
                onChange={(e) => handleInputChange(e)}
                name="sourcing"
                value={values.sourcing}
              >
                <option value="">--select--</option>
                {employees &&
                  employees?.filter(
                    (item) => item?.employee_type == sourcingType
                  )?.length > 0 &&
                  employees
                    ?.filter((item) => item?.employee_type == sourcingType)
                    ?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Loan Type</label>
              <select
                className="form-control"
                onChange={(e) => handleInputChange(e)}
                name="loan_type"
                value={values.loan_type}
              >
                <option value={"B.T.CASE"}>B.T.CASE</option>
                <option value={"REFINANCE"}>REFINANCE</option>
                <option value={"SALE PURCHASE"}>SALE PURCHASE</option>
                <option value={"EXISTING"}>EXISTING</option>

              </select>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Loan Request </label>
              <input
                className="form-control"
                placeholder="Enter Loan Request Amount"
                type="number"
                onChange={(e) => handleInputChange(e)}
                name="loan_request"
                value={values.loan_request}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Finance Company SC Name </label>
              <input
                className="form-control"
                placeholder="Enter Finance Company SC Name"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="financeCompanySCName"
                value={values.financeCompanySCName}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">file Handling Officer </label>
              <input
                className="form-control"
                placeholder="Enter File Handling Officer "
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="fileHandlingOfficer"
                value={values.fileHandlingOfficer}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor>Finance Companies</label>
              {allCompanies.length > 0 && (
                <Multiselect
                  options={allCompanies.filter(
                    (company) => company.company_type == "Finance"
                  )}
                  displayValue="company_name"
                  onSelect={handleMultiselectChange}
                  onRemove={handleMultiselectChange}
                  selectedValues={selectedCompanies}
                  style={{ zIndex:"1000" }}
                />
              )}
            </div>
          </div>
          <div className="form-buttons-w text-right">
            <span className=" text-right">
              <button
                className="btn btn-primary text-white"
                onClick={() => setActiveTab("secondStep")}
              >
                Back
              </button>
            </span>
            &nbsp;&nbsp;{" "}
            <span className=" text-right">
              <button
                className="btn btn-primary text-white"
                onClick={() => SaveLoan("fourthStep")}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <span>Save</span>
                )}
              </button>
            </span>
            &nbsp;&nbsp;
          </div>
        </div>
      </fieldset>
      <div className="row">
        <fieldset>
          <legend>
            <span>Finance Company List</span>
          </legend>
          <div className="table-responsive">
            <table className="table table-lightborder">
              <thead>
                <tr>
                  <th>Company Type</th>
                  <th>Comapny Name</th>
                  <th>Comapny Coordinator Name</th>
                  <th className="text-center">Company Contact Person </th>
                  <th className="text-center">Status</th>

                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedCompanies?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.company_type}</td>
                    <td>{item.company_name}</td>
                    <td>
                      <div>
                        <p className="m-1">{item.company_coordinator_name}</p>
                        <p>{item.company_coordinator_mobile_no}</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        {contactPerson?.filter(
                          (entry) => entry.companyId == item._id
                        ).length > 0 ? (
                          contactPerson
                            ?.filter((entry) => entry.companyId == item._id)
                            .map((item1) => (
                              <>
                                <p className="m-1 text-center">{item1?.name}</p>
                                <p className="mb-1 text-center">
                                  {item1?.mobile_no}
                                </p>
                              </>
                            ))
                        ) : (
                          <>
                            <p className="m-1 text-center">
                              {item.company_contact_person_name}
                            </p>

                            <p className="mb-1 text-center">
                              {item.company_contact_person_mobile_no}
                            </p>
                          </>
                        )}
                        {/* <br/> */}
                        <button
                          className="text-success border-0 bg-white ps-2 text-center"
                          onClick={() => handleShow1(item)}
                          disabled={
                            contactPerson?.filter(
                              (entry) => entry.companyId == item._id
                            ).length > 0
                              ? true
                              : false
                          }
                        >
                          change contact person
                        </button>
                      </div>
                    </td>
                    <td className="text-center">
                      <button
                        className="text-success border-0 bg-white ps-2"
                        onClick={() => handleShow(item)}
                      >
                        Change Status
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="text-success border-0 bg-white ps-2"
                        onClick={() => ViewHistoryFn(item)}
                      >
                        View
                      </button>
                      <br />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Loan Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Select Status</label>
          <select
            name="loanCompanyStatus"
            className="form-control"
            value={loanCompanyStatus}
            onChange={(e) => setLoanCompanyStatus(e.target.value)}
          >
            <option value="Pending Leed" selected="true">
              Pending Leed
            </option>
            <option value="Running Leed">Running Leed</option>
            <option value="Reject Leed">Reject Leed</option>
            <option value="Agree Leed">Agree Leed</option>
            <option value="Final Leed">Final Leed</option>

          </select>
          <br />
          <label>Reason</label>
          <input
            type="text"
            name="statusName"
            value={statusName}
            onChange={(e) => setStatusName(e.target.value)}
            className="form-control"
          />
          <br />
          <label>Status Description</label>
          <textarea
            type="text"
            rows={3}
            className="form-control"
            name="remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
          <br />
          <label>Date</label>
          <input
            type="date"
            className="form-control"
            name="currentDate"
            value={currentDate}
            onChange={(e) => setCurrentDate(e.target.value)}
          />
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShow(false)}>
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() => saveLoanCompanyHistory(loanCompanyStatus)}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={() => setShow1(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Contact Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label> Name</label>
          <ReactSearchAutocomplete
            items={contactPeople}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            placeholder="Search By Name"
            autoFocus
            formatResult={formatResult}
          />
          <br />
          <label> Mobile Number</label>
          <input
            type="text"
            name="mobile_no"
            value={mobile_no}
            onChange={(e) => setMobileNo(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShow1(false)}>
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() => saveContactPerson()}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              <span>Save</span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoanRequest;
